const ENV_NAME = 'uat';
const  IDLE_WARNING_DURATION = 840000;
const  IDLE_TIMEOUT_DURATION = 60000;

const LAMBDA_API_URL_01 = 'https://func-uat-medrecord-eastus-001.azurewebsites.net/api/';
const LAMBDA_API_URL_02 = 'https://func-uat-medrecord-eastus-002.azurewebsites.net/api/';
const LAMBDA_API_URL_03 = 'https://func-uat-medrecord-eastus-003.azurewebsites.net/api/';
const LAMBDA_API_URL_04 = 'https://func-uat-medrecord-eastus-004.azurewebsites.net/api/';
const LAMBDA_API_URL_05 = 'https://func-uat-medrecord-eastus-005.azurewebsites.net/api/';
const LAMBDA_API_URL_06 = 'https://func-uat-medrecord-eastus-006.azurewebsites.net/api/';
const PHP_API_URL = 'https://api.medrecordexchange.com/uat/';
const PHP_AHS_API_URL = 'https://api.medrecordexchange.com/uat/ahs_php/';

export const environment = {
  production: false,
  environmentName: ENV_NAME,
  lambdaApiUrl_01: LAMBDA_API_URL_01,
  lambdaApiUrl_02: LAMBDA_API_URL_02,
  lambdaApiUrl_03: LAMBDA_API_URL_03,
  lambdaApiUrl_04: LAMBDA_API_URL_04,
  lambdaApiUrl_05: LAMBDA_API_URL_05,
  lambdaApiUrl_06: LAMBDA_API_URL_06,
  phpApiUrl: PHP_API_URL,
  phpAhsApiUrl: PHP_AHS_API_URL,
  idleWarningDuration: IDLE_WARNING_DURATION ,
  idleTimeoutDuration: IDLE_TIMEOUT_DURATION,
};
